import { getCurrentInstance, withCtx, h } from 'vue'
import { createI18n, Translation } from 'vue-i18n'
import en from './locales/en'
import enAi from './locales/en-x-ai'

const messages = {
  en,
  'en-x-ai': enAi,
}

/** @type {const} */
const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
    },
  },
  'en-GB': {
    currency: {
      style: 'currency',
      currency: 'GBP',
    },
  },
}

export const i18nOptions = {
  legacy: true,
  allowComposition: true,
  locale: 'en',
  messages,
  numberFormats,
  // TODO: disabled due to too much noise. Consider re-enabling someday
  warnHtmlInMessage: 'off',
  /**
   * Disabled because this is so noisy in AI mode and falling back to en is required
   * in order for the 'en-x-ai' solution to not be terrible.
   */
  silentFallbackWarn: true,
}

const i18n = createI18n(i18nOptions)

const { t } = i18n.global

/**
 * i18n.global.t wrapper
 * @param  {String} key the i18n translation string
 * @param  {Object=} options the i18n options // Optional
 * @return {String}     the translated string
 */
export function translate(key, options) {
  return t(key, options)
}

/**
 * AC 21/12/22:
 * This is a workaround fix for https://github.com/intlify/vue-i18n-next/issues/729
 * When <i18n-t> is passed to the slot of a component which has its own i18n translations defined
 * then vue-i18n will look for translations there instead of in the parent component.
 *
 * The fix works by wrapping i18n-t with a component which modifies the render context (using `withCtx`)
 * to trick it into thinking it is rendering in the component context, rather than slot context.
 *
 * It's pretty weird but best I could think of and seems to work.
 *
 * If the above bug gets fixed, I have written some tests which should alert us to that and we can
 * delete this and revert to exporting the default i18n. I.e. replace the below with
 * `export default i18n`
 */
const i18nWithCtxFix = {
  install(app, _options) {
    function TranslationWithCtx(props, { slots }) {
      const inst = getCurrentInstance()
      const componentCtx = inst?.vnode?.ctx
      if (componentCtx) {
        inst.parent = componentCtx
      }
      return withCtx(() => h(Translation, props, slots), inst)()
    }
    app.component('I18nT', TranslationWithCtx)
    return app.use(i18n, { globalInstall: false })
  },
}
export { i18n }
export default i18nWithCtxFix
